<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img
            :src="appLogoImage"
            alt="logo"
          />
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('Forgot Password?') }} 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('Enter your email and we\'ll send you instructions to reset your password') }}
        </b-card-text>

        <!-- form -->
        <validation-observer ref="forgot-password-observer">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="forgotPassword"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
              :disabled="submitInProgress"
            >
              {{ $t('Send email') }}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text
          class="text-center mt-2"
          style="font-size: 14px;"
        >
          <b-link :to="{ name: 'auth-login' }">
            <feather-icon icon="ChevronLeftIcon" /> {{ $t('Back to login') }}
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@mentoring-platform-validations'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BImg,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      // validation
      required,
      email,
      submitInProgress: false
    }
  },
  methods: {
    async forgotPassword() {
      const isValid = await this.$refs['forgot-password-observer'].validate().then(success => success)
      if (isValid) {
        this.submitInProgress = true
        this.$store.dispatch('user/forgotPassword', {
          email: this.userEmail,
          locale: this.$i18n.locale
        })
          .then(() => {
            this.submitInProgress = false
            this.$root.$bvToast.toast(this.$t('We sent you an email with instructions for resetting your password.'), {
              title: this.$t('Confirmation'),
              variant: 'success',
              solid: true,
            })
            this.$router.push({ name: 'home' })
          })
          .catch(() => {
            this.submitInProgress = false
            this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
              title: this.$t('Caution'),
              variant: 'danger',
              solid: true,
            })
          })
      }
    },
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app

    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';

.brand-logo {
  img {
    width: 300px;
  }
}
</style>
